
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fap-front-primary: mat.define-palette(mat.$indigo-palette);
$fap-front-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fap-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
// $fap-front-theme: mat.define-light-theme($fap-front-primary, $fap-front-accent, $fap-front-warn);
$fap-front-theme: mat.define-light-theme((
        color: (
          primary: $fap-front-primary,
          accent: $fap-front-accent,
          warning: $fap-front-warn
        ),
        typography: mat.define-typography-config(),
        density: 0,
       ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($fap-front-theme);
@import "./assets/scss/theme/variables";
@import "./assets/scss/variables/variables.scss";

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
button {background-color: var(--primary-color);}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif !important; }
.w-90 {
    width: 90% !important;
}
.toggleTable.false {
    .minVal, .maxVal, .sumVal {
        display: none;
    }
}
.toggleTable.true {
    .minVal, .maxVal, .sumVal {
        display: table-cell;
    }
}
.custom-marker-label {
    background-color: #FF5733; /* Your desired background color */
    color: white; /* Text color */
    padding: 4px 8px; /* Padding around the label text */
    border-radius: 4px; /* Rounded corners */
    font-size: 12px; /* Font size */
  }
img {max-width: 100%;}
.toggleTable {
    th {
        vertical-align: middle;
    }
    td:not(:first-child):nth-child(4n+1) {
        background: rgba(0,0,0,0.2);
    }
    th, td {
        padding: 5px 10px!important;
    }
    .stick {
        min-width: 90px;
        &.mw120 {
            min-width: 120px;
        }
    }
    tr.mat-header-row {
        border-bottom-color: var(--primary-color);
    }
    td:not(:first-child) {
        text-align: center!important;
    }
    .minVal, .maxVal, .sumVal {
        font-size: 12px;
        color: rgba(0,0,0,0.6);
    }
    tr.rws {
        visibility: collapse;
        display: none;
        position: sticky;
        top: 43px;
        z-index: 1;
        border-bottom: 1px solid #000;
        td:first-child {
            z-index: 1;
            position: sticky;
            left: 0;
        }
    }
}
.toggleTable th:first-child {
    min-width: 110px;
    position: sticky;
    left: 0;
}
.duration {
    .mat-form-field-appearance-legacy .mat-form-field-infix {
        display: flex;
    }
}
td.stick {
    position: sticky;
    left: 0;
    // background: inherit!important;
}
.toggleTable.true {
    .minVal, .maxVal, .sumVal {
        visibility: visible;
    }
    tr.rws {
        visibility: visible;
        display: table-row;
    }
}
.overviewName {
    padding-top: 2px!important;
    position: relative;
    .main_text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    span {
        display: block;
        opacity: 0.5;
        font-size: 11px;
        line-height: 11px;
        margin-bottom: 5px;
    }
}
.theme-activityedit-form
{
    .description
    {
        .mat-form-field-infix
        {
            background-color: #A9D18E42;
         }
    }
}
.mat-select-panel {
    background: #fff;
    overflow: hidden;
    max-height: initial!important;
    // max-width: none!important;
    .mat-form-field-appearance-legacy .mat-form-field-underline {
        display: none!important;
    }
    .mat-input-element::placeholder {
        text-align: center;
        color: #333!important;
    }
    .mat-form-field.mat-form-field-hide-placeholder {
        .mat-form-field-infix {
          .mat-input-element::placeholder {
            color: #333 !important;
            -webkit-text-fill-color: #333 !important;
          }
        }
      }
    .mat-form-field {
        padding: 0 10px;
        margin: auto!important;
        background: var(--primary-color-light);
        .mat-form-field-infix {
            border: none;
            padding: 12px 0;
        }
    }
    .mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
        border: none;
    }
    
    .d-flex {
        background: var(--primary-color-light);
        margin: 0 2px;
        border-top-left-radius: 4px;
        .mat-form-field {
            background: none!important;
        }
        &.text-center {
            background: inherit;
        }
    }
    .type-name {
        color: var(--primary-color); font-weight: bold;
    }
    .type-name.green {
        color: var(--primary-color)!important; font-weight: bold!important;
    }
    .mat-option-text {
        color: #000;
        display: flex;
        justify-content: space-between!important;
        align-items: center;
        .type-name {
            color: inherit;
            font-weight: normal;
        }
    }
    .mat-form-field-flex {
        background: rgba(255,255,255,0.5);
        border: 2px solid #000;
        border-radius: 5px!important;
        position: relative;
        top: 10px!important;
        padding: 0!important;
        input::placeholder {
            text-align: center;
        }
        .mat-form-field-infix input {
            border: none;
            box-shadow: none;
            width: 100%;
            margin: auto;
            padding: 0 10px;
        }
    }
   
    .mat-form-field.mat-focused .mat-form-field-ripple {
        display: none!important;
    }
}
.tbw .mat-form-field-flex {
    background: #fff;
}

.custom-apex-tooltip {
      background: rgba(255, 255, 255, 0.9);
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 5px;
      font-size: 12px;
    }

    .custom-apex-tooltip-y-group:nth-child(2) {
        margin: 0 10px;
    }

    .custom-apex-tooltip-title {
      font-weight: bold;
    }

    .custom-apex-tooltip-series-group {
      display: flex;
    }

    .custom-apex-tooltip-marker {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      display: inline-block;
      border-radius: 50%;
    }

    .custom-apex-tooltip-text {
      font-family: Helvetica, Arial, sans-serif;
      font-size: 12px;
      display: flex;
    }

    .custom-apex-tooltip-text-label {
      font-weight: bold;
    }

    .custom-apex-tooltip-text-value {
      margin-left: 5px;
    }

    /*header*/
    #page-main-header .nav-menus {
        display: grid;
        grid-template-columns: repeat(5, min-content);
        justify-content: end;
        // background-color: #000;
    }
    
    #page-main-header .nav-menus>li {
        border: none;
        font-family: Roboto;
    }
    
    #page-main-header .create-farm {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    #page-main-header .edit-create {
        display: flex;
        align-items: center;
        justify-content: space-around;
        justify-self: baseline;
        width: 30rem;
        h5 {
            margin: 0;
            color: white;
            padding: 0.5rem 1rem 0.5rem;
        }
        button {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    #page-main-header {
        height: 60px;
        // background-color: rgba(43, 103, 76, 0.8);
        .lots span.search {
            position: absolute;
            right: 2%;
            transform: translate(-50%, -50%);
            top: 50%;
            .fa-search {
                color: white;
            }
            .fa-times {
                cursor: pointer;
            }
        }
    
        .mobile-filters {
            justify-self: flex-start;
            margin-left: 10px;
    
            .mat-select-trigger {
                cursor: pointer !important;
            }
    
            .add_circle {
                display: flex;
                align-items: center;
    
                .rounded-circle {
                    width: 33px;
                    height: 33px;
                    position: relative;
                    cursor: pointer;
                    &.r {
                      height: 35px;
                      width: 35px;
                      // background-color: var(--primary-color-light)!important;
                      background-size: 20px!important;
                      &::before {
                        display: none;
                      }
                    }
                    &.invoice_in {
                      background-image: url('/../../../assets/images/invoice_in.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.invoice_out {
                      background-image: url('/../../../assets/images/invoice_out.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.internal_note {
                      background-image: url('/../../../assets/images/note.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.all_docs {
                      background-image: url('/../../../assets/images/list.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.production_note {
                      background-image: url('/../../../assets/images/package.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                    &.config {
                      background-image: url('/../../../assets/icons/device_config.png')!important;
                      background-repeat: no-repeat!important;
                      background-position: center center!important;
                    }
                }
                .rounded-circle:before {
                    content: "+";
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    font-size: 2rem;
                    transform: translate(-50%, -50%);
                }
            }
            .add_dropdown {
                width: 133px;
                height: 86px;
                position: absolute;
                top: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
    
                div {
                    padding: 0.7rem;
                    width: 100%;
                    cursor: pointer;
                    color: #fff;
                }
            }
        }
        .language-dropdown.onhover-show-div:before, .language-dropdown.onhover-show-div:after {
            right: 5px;
            left: initial;
        }
    }
    
    @media screen and (max-width: 767px) {
      // #page-main-header .edit-create button {font-size: 12px;}
      #page-main-header .edit-create {width: auto!important;}
      .mobBtn {
        width: 33px;
        height: 33px;
        padding: 0!important;
        font-size: 18px!important;
        margin-right: 10px;
    }
    }
    #page-main-header .main-header-right {
        display: grid;
        grid-template-columns: min-content 1fr max-content;
        justify-items: center;
        padding: 0 30px;
    }
    /*header*/

.apexcharts-text tspan {font-size: 11px!important;}
apx-chart > div:first-child {height: 100%; min-height: auto!important;}
graph-percentage-widget .apexcharts-legend{position: absolute!important; left: auto!important; right: auto!important; top: auto!important; bottom: 0!important; .apexcharts-legend-series {display: inline-block;}}
/*widgets*/

    ngx-masonry {
    display: flex!important;
    flex-wrap: wrap!important;
    width: 100%!important;
    height: auto!important;
    div.\*lux5GMLWat  {order: 2; width: 100%!important;}
    div.\*lux5GSat  {order: 3; width: 100%!important;}
    div.cropeyesimple   {order: 1; width: 100%!important;}
    }
    div[ngxmasonryitem] {
        position: relative!important;
        left: auto!important;
        top: auto!important;
        // width: 100%!important;
    }


.widget_categories {
    background: var(--secondary-color);
    position: sticky;
    top: 60px;
    z-index: 2;
    // min-width: 220px;
    button {
        color: var(--secondary-text-color);
        border: none;
        font-size: 16px;
        padding: 10px 15px;
        font-weight: bold;
        background: transparent;
        &.active {
            color:var(--primary-color);
        }
    }
}

.masonry-with-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-height: 10000px;
  }

  /*dashboard*/
  .dashboard-card {
    width: 100%;
    // border: 1px solid $sidebar-hover-txt-color;
    // margin-bottom: 30px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 1px 5px 24px 0 rgba(2, 136, 209, 0.05);
    h1 {
      font-size: 20px;
      color: var(--text-color);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: normal;
      letter-spacing: -1px;
    }
    h2 {
      font-size: 16px;
      // font-weight: bold;
    //   color: $sidebar-hover-txt-color;
      text-align: right;
    }
    table {
      th {
        // background-color: $secondary-color;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        // color: $sidebar-hover-txt-color;
        border-bottom: 1px solid $primary-color;
        text-transform: lowercase;
        padding: 0 5px;
        &:not(:first-child) {
          text-align: center;
        }
        &::first-letter {
          text-transform: uppercase;
        }
      }
      td {
        font-size: 13px;
        // color: $sidebar-hover-txt-color;
        border-bottom: 1px solid $primary-color;
        &:not(:first-child) {
          text-align: right;
        }
      }
    }
  }
  /*dashboard*/

  @media screen and (max-width: 580px) {
    .bgrow {padding: 1px 5px;}
  }

@media only screen and (min-width:501px) and (max-width:767px){
    .g{
        width:calc(100% / 2 - 2 * 5px);
        max-width:calc(100% - 2 * 5px)
    }
    .s11{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 50px!important;
        .dashboard-card, .dash_cont {
            min-height: 50px!important;
        }
    }
    .s12{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 100px!important;
        .dashboard-card, .dash_cont {
            min-height: 100px!important;
        }
    }
    .s13{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 150px!important;
        .dashboard-card, .dash_cont {
            min-height: 150px!important;
        }
    }
    .s14{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 200px!important;
        .dashboard-card, .dash_cont {
            min-height: 200px!important;
        }
    }
    .s15{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 250px!important;
        .dashboard-card, .dash_cont {
            min-height: 250px!important;
        }
    }
    .s16{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 300px!important;
        .dashboard-card, .dash_cont {
            min-height: 300px!important;
        }
    }
    .s17{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 350px!important;
        .dashboard-card, .dash_cont {
            min-height: 350px!important;
        }
    }
    .s18{
        width:calc(100% / 2 * 1 - 2 * 5px);
        min-height: 400px!important;
        .dashboard-card, .dash_cont {
            min-height: 400px!important;
        }
    }
    .s21{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 50px!important;
        .dashboard-card, .dash_cont {
            min-height: 50px!important;
        }
    }
    .s22{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 100px!important;
        .dashboard-card, .dash_cont {
            min-height: 100px!important;
        }
    }
    .s23{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 150px!important;
        .dashboard-card, .dash_cont {
            min-height: 150px!important;
        }
    }
    .s24{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 200px!important;
        .dashboard-card, .dash_cont {
            min-height: 200px!important;
        }
    }
    .s25{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 250px!important;
        .dashboard-card, .dash_cont {
            min-height: 250px!important;
        }
    }
    .s26{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 300px!important;
        .dashboard-card, .dash_cont {
            min-height: 30px!important;
        }
    }
    .s27{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 350px!important;
        .dashboard-card, .dash_cont {
            min-height: 350px!important;
        }
    }
    .s28{
        width:calc(100% / 2 * 2 - 2 * 5px);
        min-height: 400px!important;
        .dashboard-card, .dash_cont {
            min-height: 400px!important;
        }
    }
}

@media (min-width: 768px) {
    * {
      scrollbar-width: thin;
      scrollbar-color: #d1d1d1 transparent;
    }
  
    *::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
  
    *::-webkit-scrollbar-thumb {
      background-color: #d1d1d1;
      border-radius: 5px;
    }
  }

@media only screen and (min-width:768px) and (max-width:1280px){
    .g{
        width:calc(100% / 3 - 2 * 5px);
        max-width:calc(100% - 2 * 5px)
    }
    .m11{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    .m12{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    .m13{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    .m14{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    .m15{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m16{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    .m17{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    .m18{
        width:calc(100% / 4 * 1 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    .m21{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    .m22{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    .m23{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    .m24{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    .m25{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m26{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    .m27{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    .m28{
        width:calc(100% / 4 * 2 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    .m31{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    .m32{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    .m33{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    .m34{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    .m35{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m35{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m36{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    .m37{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    .m38{
        width:calc(100% / 4 * 3 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    .m41{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    .m42{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    .m43{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    .m44{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    .m45{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    .m46{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    .m47{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    .m48{
        width:calc(100% / 4 * 4 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
}

@media only screen and (min-width:1281px) {
    .g{
        width:calc(100% / 4 - 2 * 5px);
        max-width:calc(100% - 2 * 5px)
    }
    // .l1{
    //     width:calc(100% / 4 * 1 - 2 * 5px)
    // }
    // .l2{
    //     width:calc(100% / 4 * 2 - 2 * 5px)
    // }
    // .l3{
    //     width:calc(100% / 4 * 3 - 2 * 5px)
    // }
    // .l4{
    //     width:calc(100% / 4 * 4 - 2 * 5px)
    // }
    .example-box, .field {
    &.xl1{
        width:calc(100% / 12 * 1 - 2 * 5px)
    }
    &.xl2{
        width:calc(100% / 12 * 2 - 2 * 5px)
    }
    &.xl3{
        width:calc(100% / 12 * 3 - 2 * 5px)
    }
    &.xl4{
        width:calc(100% / 12 * 4 - 2 * 5px)
    }
    &.xl5{
        width:calc(100% / 12 * 5 - 2 * 5px)
    }
    &.xl6{
        width:calc(100% / 12 * 6 - 2 * 5px)
    }
    &.xl7{
        width:calc(100% / 12 * 7 - 2 * 5px)
    }
    &.xl8{width:calc(100% / 12 * 8 - 2 * 5px)
    }
    &.xl9{width:calc(100% / 12 * 9 - 2 * 5px)
    }
    &.xl10{width:calc(100% / 12 * 10 - 2 * 5px)
    }
    &.xl11{width:calc(100% / 12 * 11 - 2 * 5px)
    }
    &.xl12{width:calc(100% / 12 * 12 - 2 * 5px)
    }




    &.l11{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l12{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l13{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l14{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l15{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l16{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l17{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l18{
        width:calc(100% / 12 * 1 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l21{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l22{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l23{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l24{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l25{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l26{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l27{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l28{
        width:calc(100% / 12 * 2 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l31{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l32{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l33{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l34{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l35{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l36{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l37{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l38{
        width:calc(100% / 12 * 3 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l41{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l42{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l43{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l44{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l45{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l46{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l47{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l48{
        width:calc(100% / 12 * 4 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l51{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l52{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l53{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l54{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l55{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l56{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l57{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l58{
        width:calc(100% / 12 * 5 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l61{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l62{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l63{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l64{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l65{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l66{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l67{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l68{
        width:calc(100% / 12 * 6 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l71{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l72{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l73{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l74{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l75{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l76{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l77{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l78{
        width:calc(100% / 12 * 7 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l81{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l82{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l83{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l84{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l85{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l86{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l87{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l88{
        width:calc(100% / 12 * 8 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l91{
        width:calc(100% / 12 * 9 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l92{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l93{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l94{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l95{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l96{
        width:calc(100% / 12 * 8 - 9 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l97{
        width:calc(100% / 12 * 9 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l98{
        width:calc(100% / 12 * 9 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l101{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l102{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l103{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l104{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l105{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l106{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l107{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l108{
        width:calc(100% / 12 * 10 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l111{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l112{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l113{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l114{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l115{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l116{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l117{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l118{
        width:calc(100% / 12 * 11 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
    &.l121{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 50px!important;
        .dashboard-card, .dash_cont {
            height: 50px!important;
        }
    }
    &.l122{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 100px!important;
        .dashboard-card, .dash_cont {
            height: 100px!important;
        }
    }
    &.l123{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 150px!important;
        .dashboard-card, .dash_cont {
            height: 150px!important;
        }
    }
    &.l124{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 200px!important;
        .dashboard-card, .dash_cont {
            height: 200px!important;
        }
    }
    &.l125{
        width:calc(100% / 12 * 102 - 2 * 5px);
        height: 250px!important;
        .dashboard-card, .dash_cont {
            height: 250px!important;
        }
    }
    &.l126{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 300px!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
        }
    }
    &.l127{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 350px!important;
        .dashboard-card, .dash_cont {
            height: 350px!important;
        }
    }
    &.l128{
        width:calc(100% / 12 * 12 - 2 * 5px);
        height: 400px!important;
        .dashboard-card, .dash_cont {
            height: 400px!important;
        }
    }
}
.bgrow .example-box fap-pool-widget .dash_cont {
    width: auto!important;
}

.example-box.pool {height: auto!important;}

}

.pac-container {
    z-index: 10000;
}

.position_info span {
    color: #FDD836;
}

@media screen and (max-width: 992px) {
    .example-box {
        &.s11, &.s12, &.s13, &.s14, &.s15, &.s21, &.s22, &.s23, &.s24, &.s25, &.s26, &.s27, &.s28 {
        min-height: 300px!important;
        height: auto!important;
        .dashboard-card, .dash_cont {
            height: 300px!important;
            .row {
                justify-content: space-between;
                [class*='col'] {
                    width: auto;
                }
            }
        }
    } }
    .example-box {margin: 5px 0 0!important; &:last-child {margin-bottom: 5px;}}
}

@media screen and (max-width: 767px){
    // *::-webkit-scrollbar {width: 2px;}
    svg.feather.feather-more-horizontal {color: #fff;}
}

/*widgets*/
ngx-masonry div[ngxmasonryitem] {margin: 0 5px 10px!important;}
.slash ~ .slash {display: none!important;}
.mat-expansion-panel:not(.mat-expanded):not([aria-disabled='true']) .mat-expansion-panel-header:hover {background: transparent;}
.mat-optgroup-label {height: auto!important;}
.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 2px!important;
    background: #000;
}
.mat-dialog-container {padding: 0!important;}
.tbg .mat-form-field-appearance-legacy .mat-form-field-infix {background: var(--primary-color-light);}
.mat-form-field-appearance-legacy .mat-form-field-infix{
    padding: 10px 3px;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label{margin: 5px 0!important;}
.bx {height: 185px; width: 150px;}
.bx .mat-form-field-appearance-legacy .mat-form-field-wrapper {height: 100%;}
.bx .mat-form-field-flex, .combo_box .mat-form-field-flex {height: 100%;}
.combo_box > * {height: 100%;}
.combo_box .mat-form-field-appearance-legacy .mat-form-field-wrapper {height: 100%;}
.rw.h10 {height: 10px;}
.simulations table th span, .simulations table td span {
    display: block;
}
.scroll_wrap {
    overflow-y: scroll;
    max-height: 200px;
}
.custom_box {padding: 5px 10px!important;
    .custom_title {
        font-size: 1.1rem!important;
    }
}
.custom_header, .card-header, .header {
    .custom_title, p {
        font-size: 1.1rem!important;
        font-weight: 500;
    }
}
.cust_pad_fields {
    padding: 10px 5px 0 10px;
}
.header {
    min-height: 50px;
    max-height: 50px;
    padding: 10px;
    background-color: var(--primary-color);
    p {
        padding: 2px!important;
    }
}
.auth-bg {
    // background-image: url('../src/assets/images/fap/Background.jpg');
    position: relative;
    .bg_img {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
        background-size: cover;
    }
}

.card-header {
    min-height: 50px;
    max-height: 50px;
    padding: 0 10px;
}

.mat-form-field.disbld {
    .mat-form-field-infix {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.2);
            z-index: 4;
        }
    }
}
.cut-down {
    .equipment-form {
        display: block!important;
    }
    .half_width {
        width: 48%;
        float: left;
        margin-right: 4%;
        &:nth-child(even) {
            margin-right: auto;
        }
    }
}
.mat-form-field-ripple {
    background: transparent!important;
}
.mat-datepicker-content-touch {margin: auto!important; width: max-content; max-width: 296px;}
.mat-datepicker-content-touch .mat-calendar{height: auto!important; width: 296px!important;}
.dashboard-card .row.mx-2 {width: 100%;}
.fa.fa-plus {
    background: var(--primary-color);
    border-radius: 50%;
    padding: 8px 10px;
    color: transparent!important;
    height: 35px;
    width: 35px;
    position: relative;
    &::before, &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 4px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
    }
    &::after {
        width: 4px;
        height: 16px;
    }
}
.mat-optgroup-label {
        // display: flex !important;
        .actions{
            margin-left: auto;
            i{
                cursor: pointer;
                font-size: 20px;
            }
            .fa-trash{
                color: var(--primary-color)!important;
            }
            .fa-edit{
                color: green;
            }
        }
    }
    .input-group{overflow: hidden;}
    i.fa-trash {
        cursor: pointer;
        color: var(--primary-color);
    }
    .actionBtn span:nth-child(2) i.fa-trash {color: #212529!important;}
.mat-form-field-appearance-fill .mat-form-field-underline::before {background: transparent;}
.combo_box, .custom_border {
    .mat-form-field-appearance-legacy {
      .mat-form-field-underline {
        background: none !important;
        height: 0 !important;
      }
  
    //   .mat-form-field-infix {
    //     padding: 4px;
    //   }
  
    //   .mat-select-value-text {
    //     font-weight: 600;
    //   }
  
    //   .mat-form-field-label {
    //     transform: none !important;
    //     font-size: 10px !important;
    //     position: absolute;
    //     top: 4px;
    //     width: 100% !important;
    //     color: var(--primary-color);
    //   }
    }
  }
.dash_cont {width: 100%;}
.cut-down, .col-xl-4.col-5, .col-xl-3.col-4 {
    // .s11, .s12, .s13, .s14, .s15,
    // .s21, .s22, .s23, .s24, .s25, 
    // .m11, .m12, .m13, .m14, .m15, 
    // .m21, .m22, .m23, .m24, .m25, 
    // .m31, .m32, .m33, .m34, .m35, 
    // .l11, .l12, .l13, .l14, .l15,
    // .l21, .l22, .l23, .l24, .l25, 
    // .l31, .l32, .l33, .l34, .l35,
    // .l41, .l42, .l43, .l44, .l45,
    // .l51, .l52, .l53, .l54, .l55, 
    // .l61, .l62, .l63, .l64, .l65
    // {
    //     width: auto!important;
    // }
    .dashboard-soil-card {
        min-width: 320px;
        height: 100%;
        table td {
            height: auto;
        }
    }
}
ng-image-slider {
    height: calc(100% - 30px)!important;
}
.dashboard-card {
    // max-height: calc(100% - 30px);
    position: relative;
    height: 100%;
    h1 {
        cursor: all-scroll;
    }
    table th {
        background: var(--secondary-color);
    }
}
.dashboard-soil-card h1 {
    cursor: all-scroll;
}
.custom_border {
    z-index: 1;
    &.col-6 {
        &::after {
            width: calc(100% - 30px);
        }
    }
    &::after {
        content: '';
        border-bottom: 3px solid #000;
        bottom: 17px;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
.ng-image-slider .ng-image-slider-container .main, .ng-image-slider {height: 100%!important;}
.position-relative.crop-eye-img {
    height: calc(100% - 48px);
    display: flex;
    img {
        height: auto !important;
        width: 100%;
        max-height: 100%;
    }
}
fap-soil-widget table {height: calc(100% - 30px);}
.bgrow .example-box fap-pool-widget .dash_cont {height: auto!important; width: auto!important; border-radius: 0;}
fap-image-slider-widget .row:nth-child(2){height: calc(100% - 60px); position: relative; z-index: 1;}
// dashboard-soil-card .dash_cont {height: calc(100% - 30px);}
fap-image-slider-widget .row.border-green-a, .bg-green-b.row.mx-0 {height: auto!important;}
.dash_cont .row:nth-child(1) .col-md-7, .dash_cont .row:nth-child(1) .col-md-6, .dash_cont .row:nth-child(1) .col-md-4:nth-child(2) {opacity: 0; transition: .5s ease all;}
.dash_cont .row:nth-child(1) .col-md-4:nth-child(1):hover, .dash_cont .row:nth-child(1) .col-md-3:nth-child(1):hover {
    & ~ div {
        opacity: 1;
    }
}
.dashboard-soil-card table th {max-width: 50px;}
.bg_white {background: #fff;}
.bg_pink {background: $bg_pink;}
.bg_gray {background: $bg_gray;}
.bg_yellow {background: $bg_yellow;}
.bg_green {background: $bg_green;}
.bg_brown {background: $bg_brown;}
.bg_orange {background: $bg_orange;}
.bg_blue {background: $bg_blue;}
.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

.conf_icon {
    display: inline-block;
    padding: 2px 0 2px 15px;
    cursor: pointer;
    i {
        display: block;
        width: 4px;
        height: 4px;
        background: #333;
        &:not(:last-child) {
            margin-bottom: 3px;
        }
    }
}
.col-md-2.text-right {padding-right: 0;}
td.name {text-align: left!important;}
.nimg .delt_btn i {box-shadow: rgba(169, 209, 142, 0.2);}
.dashboard-card .row:first-child .col-md-4{&:hover ~ .col_lot_name{opacity: 1!important;} flex: auto; max-width: 80%!important;}
.col_lot_name {position: absolute!important; left: 0; top: 36px;
    width: auto!important; box-shadow: 0 0 10px 1px rgba(0,0,0,0.2); z-index: 1;
    padding: 10px;
    background: #fff; h2 {text-align: left!important; margin: 0;}}
    .custom_modal input {width: 100%!important;}
    .img_place_bg {border: 2px solid #d0d0d0; background: #fff; padding: 1px; border-radius: 5px; display: flex; align-items: center; justify-content: center; &.ph {background: #fff!important;} }
    .user_im {img {background: transparent; width: 60px; height: 80px; object-fit: contain;} width: 72px; height: 94px; padding: 2px; }
    img.portrait {min-width: 140px; object-fit: contain!important;}
    img.landscape {min-width: 200px; object-fit: contain!important}
*{letter-spacing: -0.02em!important;}
.dashboard-card table.toggleTable  th{span {display: block; font-weight: bold; color: var(--primary-color); text-transform: uppercase;}}
fap-culture-edit{width: 100%;}
.toggle_head {
    position: sticky;
    top: 0;
    z-index: 1;
}
.toggleTable.true tr.rws td {padding: 0 5px!important;}

.devices_table {
    th {
        // &:not(:first-child) span {
        //     text-align: center;
        //     &::before {
        //         content: '(';
        //     }
        //     &::after {
        //         content: ')';
        //     }
        // } 
        span {
            display: block;
            position: relative;
            text-transform: capitalize;
        }
    }
    td {
        .box {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
        span.lots {
            color: gray;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        span {
            color: gray;
            &.unit {
                padding-left: 5px;
            }
            &.small_text {
                font-size: 12px;
                display: block;
                span {
                    padding-left: 5px;
                }
            }
            &.btn {
                background-color: var(--primary-color);
                border: none;
                border-radius: 50%;
                width: 33px;
                padding: 7px;
                height: 33px;
                text-align: center;
            }
            ~ span {display: block; font-size: 12px;}
            img {
                max-width: 30px;
                cursor: pointer;
            }
        }
    }
}
.card-header {
    margin-bottom: 0;
    padding: 10px;
}
.nc {overflow-x: hidden;}
.mat-list-base .mat-list-item .mat-list-item-content{padding: 0 10px!important;}
// Gantt Chart styles //
.gtaskname div, .gtaskname, .gadditional, .gres, .gdur, .gcomp, .gcost, .gtaskheading, .gstartdate div, .gplanstartdate, .gplanenddate, .gstartdate, .genddate, .gtaskheading, .gmajorheading, .gminorheading, .gtaskcellwkend, .gtaskcellcurrent, .gminorheadingwkend {font-size: 0.8rem;}
.gmainleft {width: 50%;}
.gmainleft, .gmainright {resize: none;}
// .gtasktable td, .gtasktableh td, .gcharttableh td, .gcharttable td {padding: 5px 10px;}
// .gcharttableh td > div {min-height: 14px;}
.gcharttable, .gcharttableh {table-layout: auto;}
.apexcharts-canvas {max-height: 100%!important;}
// .gtaskcelldiv {min-height: 14px;}
// Gantt Chart styles //
.card-text-link {
        text-decoration: underline;
        color: var(--primary-color);
        cursor: pointer;
}
.extra_pages {
    padding: 15px;
}
.custom-white-marker-label {
    background: #fff;
    padding: 5px;
    border-radius: 15px;
}
.devices_table td span.custom_btn_img {
    img {
        max-width: 100%;
    }
}
.has_image_value {
    display: flex;
    justify-content: center;
    img {
        max-width: 33px;
        object-fit: contain;
    }
    div:nth-child(2) {
        padding-left: 10px;
    }
}
.auth-bg .authentication-box .card {
    background: transparent;
    .card-body {
        background-color: rgba(255,255,255,0.9);
    }
}

.with_units {
    .mat-form-field-appearance-legacy .mat-form-field-underline {background: transparent!important;}
    &::before {
        content: '';
        position: absolute;
        bottom: 16px;
        background: var(--dark-text);
        height: 2.2px;
        width: 100%;
    }
    .col-9 {
        padding-right: 5px!important;
    }
    .col-3 {
        color: var(--primary-color);
    }
}

.dark_bg {
    .mat-form-field-appearance-legacy .mat-form-field-label{
        color: var(--dark-text);
    }
    .mat-select-value {
        color: var(--dark-text);
    }
    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background: var(--dark-text);
    }
    .mat-select-arrow {
        color: var(--dark-text);
    }
}

.mat-tab-label {min-width: 30px!important;}
.mat-tab-body-content {overflow: hidden!important;}
.mat-tab-label-active {
    color: var(--primary-text-color);
    background: var(--primary-color);
    opacity: 1!important;
}
.mat-ink-bar {display: none!important;}
.page-wrapper .page-main-header {z-index: 31!important;}

@media only screen and (max-width: 991px) {
    .page-wrapper .page-main-header .main-header-right .nav-right {
        position: relative!important;
        padding: 0;
        right: -5px;
    }
}

@media screen and (max-width: 768px) {
    .ng-image-slider .ng-image-slider-container .main, .ng-image-slider {height: 180px!important;}
    .position-relative.crop-eye-img {height: calc(100% - 82px);}
    .nc {width: 100%!important;}
    .example-box {width: 100%!important; position: unset!important; #parent {min-height: 250px;}}
    td.dateTime, .dashboard-card table th:first-child{min-width: 65px;}
    .switch-sm .switch {margin-right: 0!important;}
}
@media screen and (max-width: 767px) {
    #page-main-header .edit-create h5 {display: none;}
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {margin: 0 0 30px!important;}
    // .sidebar-footer {background-color: var(--primary-color);}
    fap-upload-avatar{width: 100%; height: 215px!important; display: inline-block; margin-bottom: -15px;}
    .cdk-overlay-pane {max-width: 90vw!important}
    .nc {width: 100%!important; overflow: auto!important;}
    .post_card {margin-bottom: 10px;}
    ngx-masonry div[ngxmasonryitem] {margin: 0 0 10px!important;}
    scheduler-edit .col-12 {padding-left: 10px; padding-right: 10px;}
    .cust_pad_fields {padding: 10px;}
    .mat-select-panel {max-width: 300px!important;}
    .mat-option-text .name.t {width: calc(100% - 54px);}
    .custom_header .custom_title, .custom_header p, .card-header .custom_title, .card-header p, .header .custom_title, .header p, .card-title, .card-title h4, .title h5 {font-size: 1.1rem!important;}
    ngx-masonry{display: block!important;}
    div.dashboard-card h1 {font-size: 18px;}
    img.portrait {min-width: 140px;}
    img.landscape {min-width: 200px;}
}
@media screen and (max-width: 580px) {
    .c-eye {max-height: 200px; overflow: auto;}
    .position-relative.crop-eye-img {height: calc(100% - 40px);}
    #page-main-header .lots span.search{right: -10px!important;}
    .dashboard-card{padding: 10px 10px!important;}
    fap-chart-widget, graph-range-widget, graph-percentage-widget {
        .dashboard-card{padding: 15px 0!important; overflow: hidden;}
        .row {padding: 0 10px;}
    }
    // fap-chart-widget .cheat_row {width: calc(100% + 20px); margin-left: -10px;}
    ngx-masonry {height: auto!important;}
    .s11, .s12, .s13, .s14, .s15, .s16, .s17, .s18, .s21, .s22, .s23, .s24, .s25, .s26, .s27, .s28 {
        height: 300px;
       .dash_cont {
        height: 300px;
       }
    }
    .s11, .s12, .s13, .s14, .s15, .s16, .s17, .s18, .s21, .s22, .s23, .s24, .s25, .s26, .s27, .s28 {
        &.pool {
            height: auto;
        }
        fap-pool-widget {
            .dash_cont {
                height: auto;
            }
        }
    }
    .example-box {#parent {min-height: 200px; height: 100%;}}
    .cut-down .half_width {width: 100%; float: none; margin-right: 0;}
    fap-alert-widget {height: 100%; #parent{height: 100%!important;}}
    $widget-selector: fap-crop-eye-simple-widget, fap-chart-widget graph-range-widget, graph-percentage-widget;

    @for $i from 11 through 28 {
        .s#{$i} #{$widget-selector} .dashboard-card, .s#{$i} #{$widget-selector} .dash_cont {
            height: 320px;
        }
    }
    .dashboard-card .row .col-md-2{padding-right: 15px!important;}
    .bgrow {padding: 10px!important;}
    .example-box #parent{overflow: hidden;}

    fap-table-widget div[class^='s'], fap-table-widget div[class*=' s']{ 
        height: 300px!important;
    }
}